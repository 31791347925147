import { useMemo } from 'react';

import { useFetchAnyWorkspacePermissions } from '~/hooks/useFetchAnyWorkspacePermissions';
import { useCurrentWorkspaceAddOns } from '~/swr-hooks/workspaces/addOns/useCurrentWorkspaceAddOns';
import { isAddOnEnabled } from '~/utils/addOns';
import { canViewAddOns } from '~/utils/permissions/workspacePermissions';

export const useCanSeePrivatePeople = () => {
  const { data: permissions } = useFetchAnyWorkspacePermissions();

  const { data: addOns, isLoading } = useCurrentWorkspaceAddOns({ enabled: canViewAddOns(permissions) });

  const canSeePrivatePeople = useMemo(
    () => !!addOns?.data.some((addOn) => addOn.key === 'facialRecognition' && isAddOnEnabled(addOn)),
    [addOns],
  );

  return {
    canSeePrivatePeople,
    isLoading,
  };
};
